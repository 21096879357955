.note-width {
  width: rem(150);
}

.complete-note {
  display: none;
}

.dt-note:hover + .complete-note {
  display: block;
  background-color: white;
  z-index: 9999;
}

.complete-note:hover {
  display: block;
  background-color: white;
  z-index: 9999;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}
