//------------------------------------------------------------------------------
// BODY

body {
  @include font-size($min-font-size, $max-font-size);
  background-color: $white;
  color: $body-color;
  font-feature-settings: "liga", "kern";
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $line-height-base;
  margin: 0;
  min-width: $body-min-width;
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  text-rendering: geometricPrecision;
  -webkit-text-size-adjust: 100%;
}


//------------------------------------------------------------------------------
// HEADING

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $black;
  line-height: 1.2;
  margin-top: 0;

  small {
    font-size: 85%;
  }
}

h1,
.h1 {
  @include font-size($h1-min-font-size, $h1-max-font-size);
  margin-bottom: rem($spacer * 2);
}

h2,
.h2 {
  @include font-size($h2-min-font-size, $h2-max-font-size);
  margin-bottom: rem($spacer);
  text-transform: uppercase;
}

h3,
.h3 {
  @include font-size($h3-min-font-size, $h3-max-font-size);
  margin-bottom: rem($spacer);
}

h4,
.h4 {
  @include font-size($h4-min-font-size, $h4-max-font-size);
  line-height: $line-height-base;
  margin-bottom: rem($spacer);
}

h5,
.h5 {
  @include font-size($h5-min-font-size, $h5-max-font-size);
  line-height: $line-height-base;
  margin-bottom: rem($spacer);
}


//------------------------------------------------------------------------------
// PARAGRAPGS

p {
  margin-bottom: rem(14);
  margin-top: 0;
}


//------------------------------------------------------------------------------
// LINKS

a {
  background-color: transparent;
  color: $secondary;
  fill: $secondary;
  text-decoration: none;

  &:focus {
    outline: rem(2) solid $primary;
    outline-offset: rem(1);
    position: relative;
    text-decoration: none;
    z-index: 3000;
  }

  &:hover {
    color: $primary;
    fill: $primary;
  }

  &[tabindex="-1"],
  &[data-focus-method="mouse"],
  &[data-focus-method="touch"] {
    outline: 0;
  }

  &.disabled {
    opacity: 0.65;
    pointer-events: none;
  }
}


//------------------------------------------------------------------------------
// HORIZONTAL RULES

hr {
  border: 0;
  border-top: rem($border-width) solid $gray-400;
  box-sizing: content-box;
  height: 0;
  margin-bottom: rem(16);
  margin-top: rem(16);
  overflow: visible;
}


//------------------------------------------------------------------------------
// EMPHASIS

small,
.small {
  font-size: $small-font-size;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: none;
}


//------------------------------------------------------------------------------
// LISTS

ol,
ul {
  margin-bottom: rem(16);
  margin-top: 0;

  ol,
  ul {
    margin-bottom: 0;
  }
}

dl {
  margin-bottom: rem(16);
  margin-top: 0;
}

.list-styled {
  list-style: escape-svg(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect fill='#{$primary}' height='7' width='7' x='0' y='1'/></svg>"));
  margin-left: 0;
  padding-left: rem(17);
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}


//------------------------------------------------------------------------------
// SUB AND SUP

sub,
sup {
  font-size: em($sub-sup-font-size);
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: em(-7);
}

sup {
  top: em(-3);
}
